import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGridPremium,
  GridToolbar,
} from '@mui/x-data-grid-premium';
import { Box, CircularProgress, Paper, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { API_BASE_URL } from 'config';
import axios from 'axios';

import { formatCurrency } from 'utils/numberUtils';

const Vehicles = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['S', 'T']); // Default status filter
  const [stockTypeFilter, setStockTypeFilter] = useState(['NEW']); // Default stock type filter

  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated) return;

      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.get(
          `${API_BASE_URL}/api/vehicles/${user?.dealerid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              status: statusFilter,
              stockType: stockTypeFilter, // Send as an array
            },
          },
        );
        setVehicle(response.data);
      } catch (error) {
        console.error('Error fetching vehicle data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [isAuthenticated, user?.dealerid, getAccessTokenSilently, statusFilter, stockTypeFilter]);

  const columns = [
    {
      field: 'StockNo',
      headerName: 'Stock No.',
      width: 100,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'EntryDate',
      headerName: 'Age',
      width: 100,
      valueFormatter: (value) => {
        const entryDateString = value;
        const entryDate = new Date(entryDateString);
        const today = new Date();
        const timeDiff = today.getTime() - entryDate.getTime();
        const daysInStock = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysInStock;
      },
    },
    {
      field: 'StockType',
      headerName: 'Stock Type',
      width: 100,
    },
    {
      field: 'Year',
      headerName: 'Year',
      width: 75,
    },
    {
      field: 'MakeName',
      headerName: 'Make',
      width: 100,
    },
    {
      field: 'ModelName',
      headerName: 'Model',
      width: 150,
    },
    {
      field: 'TrimLevel',
      headerName: 'Trim',
      width: 150,
    },
    {
      field: 'ModelNo',
      headerName: 'Model No.',
      width: 120,
    },
    {
      field: 'Mileage',
      headerName: 'Mileage',
      width: 100,
    },
    {
      field: 'Color',
      headerName: 'Color',
      width: 100,
    },
    {
      field: 'InteriorColor',
      headerName: 'Interior Color',
      width: 150,
    },
    {
      field: 'VIN',
      headerName: 'VIN',
      width: 200,
    },
    {
      field: 'Price1',
      headerName: 'Retail',
      width: 120,
      valueFormatter: (value) => {
        if (value == null) {
            return '';
        }
        return formatCurrency(value);
      }
    },
    {
      field: 'Price2',
      headerName: 'Invoice',
      width: 120,
      valueFormatter: (value) => {
        if (value == null) {
            return '';
        }
        return formatCurrency(value);
      }
    },
    {
      field: 'Balance',
      headerName: 'Balance',
      width: 120,
      valueFormatter: (value) => {
        if (value == null) {
            return '';
        }
        return formatCurrency(value);
      }
    },
    {
      field: 'LicenseExpirationDate',
      headerName: 'LicenseExpirationDate',
      width: 120,
    },
    {
      field: 'InventorySourceCode',
      headerName: 'Source Code',
      width: 150,
    },
    {
      field: 'InventorySourceName',
      headerName: 'Source Name',
      width: 150,
    },
  ];

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatusFilter(typeof value === 'string' ? value.split(',') : value);
  };

  const handleStockTypeChange = (event) => {
    const value = event.target.value;
    setStockTypeFilter(typeof value === 'string' ? value.split(',') : value); // Set multiple stock types
  };

  return (
    isAuthenticated && (
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <Typography variant="h6">Vehicle</Typography>
        </Box>
        <Box sx={{ padding: 2, display: 'flex', gap: 2 }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              multiple
              value={statusFilter}
              onChange={handleStatusChange}
              label="Status"
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="O">On Order</MenuItem>
              <MenuItem value="P">In Production</MenuItem>
              <MenuItem value="I">Invoice Received</MenuItem>
              <MenuItem value="T">In Transit</MenuItem>
              <MenuItem value="S">In Stock</MenuItem>
              <MenuItem value="H">On Hold</MenuItem>
              <MenuItem value="R">Not Available for Sale</MenuItem>
              <MenuItem value="G">Sold</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth>
            <InputLabel>Stock Type</InputLabel>
            <Select
              multiple
              value={stockTypeFilter}
              onChange={handleStockTypeChange}
              label="Stock Type"
              renderValue={(selected) => selected.join(', ')}
            >
              <MenuItem value="NEW">New</MenuItem>
              <MenuItem value="USED">Used</MenuItem>
              <MenuItem value="DEMO">Demo</MenuItem>
              <MenuItem value="LEASE">Lease</MenuItem>
              <MenuItem value="FLEET">Fleet</MenuItem>
              <MenuItem value="RENTAL">Rental</MenuItem>
              <MenuItem value="MISC">Miscellaneous</MenuItem>
              <MenuItem value="SPECIAL_ORDER">Special Order</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ height: 'calc(100vh - 300px)', width: '100%' }}>
            <DataGridPremium
              sx={{
                borderRadius: 0,
                border: 'none',
              }}
              density="compact"
              rows={vehicle}
              columns={columns}
              getRowId={(row) => row._id} // Define the unique 'id' property for each row
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              loading={loading}
              checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        )}
      </Paper>
    )
  );
};

export default Vehicles;
