import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthenticationGuard } from './components/authentication-guard';
import {
  Home,
  Dashboard,
  Inventory,
  Coversheet,
  CarDeals,
  Trades,
  Vehicles,
  CoverSheetLog,
  Reservations,
  Lenders,
  Rates,
  Tiers,
  Models,
  Fees,
  Desking,
  RepairOrders,
  SalesManagerRanking,
  FinanceRanking,
  Commissions,
  GasLog,
  Expenses,
  Logout,
  Purples,
  Desklog,
  CustomerLogForm,
  ManualDealInput,
  LeaseCalculator,
  ClosedRepairOrders,
  TechnicianHoursTable,
  WeekendSummaryTable,
  CSVUploadComponent,
  ExpensesDataGrid,
  Income,
  ReportBuilder,
  Pace,
  Callback,
  AdminInterface,
  UserProfile,
} from './pages';
import LoadingComponent from './components/misc/LoadingComponent';
import { LicenseInfo } from '@mui/x-license';
import './App.css';

LicenseInfo.setLicenseKey(
  '511ea655136f09f35a6a8d79d6b0d0a6Tz04NzkzNCxFPTE3NDM5Nzk1MTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y', // This is the license key for the DataGridPremium
);

export const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/leasecalculator" element={<LeaseCalculator />} />
      <Route
        path="/admin"
        element={<AuthenticationGuard component={AdminInterface} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={UserProfile} />}
      />
      <Route
        path="/dashboard"
        element={<AuthenticationGuard component={Dashboard} />}
      />
      <Route
        path="/experiment/quickswipe"
        element={<AuthenticationGuard component={CustomerLogForm} />}
      />
      <Route
        path="/sales/inventory"
        element={<AuthenticationGuard component={Inventory} />}
      />
      <Route
        path="/sales/trades"
        element={<AuthenticationGuard component={Trades} />}
      />
      <Route
        path="/sales/vehicles"
        element={<AuthenticationGuard component={Vehicles} />}
      />
      <Route
        path="/sales/coversheet"
        element={<AuthenticationGuard component={Coversheet} />}
      />
      <Route
        path="/experiment/csvupload"
        element={<AuthenticationGuard component={CSVUploadComponent} />}
      />
      <Route
        path="/experiment/desklog"
        element={<AuthenticationGuard component={Desklog} />}
      />
      <Route
        path="/experiment/manualdealinput"
        element={<AuthenticationGuard component={ManualDealInput} />}
      />
      <Route
        path="/lenders"
        element={<AuthenticationGuard component={Lenders} />}
      />
      <Route
        path="/tiers"
        element={<AuthenticationGuard component={Tiers} />}
      />
      <Route
        path="/models"
        element={<AuthenticationGuard component={Models} />}
      />
      <Route
        path="/rates"
        element={<AuthenticationGuard component={Rates} />}
      />
      <Route path="/fees" element={<AuthenticationGuard component={Fees} />} />
      <Route
        path="/sales/desking"
        element={<AuthenticationGuard component={Desking} />}
      />
      <Route
        path="/service/closedrepairorders"
        element={<AuthenticationGuard component={ClosedRepairOrders} />}
      />
      <Route
        path="/service/technicianhours"
        element={<AuthenticationGuard component={TechnicianHoursTable} />}
      />
      <Route
        path="/service/repairorders"
        element={<AuthenticationGuard component={RepairOrders} />}
      />
      <Route
        path="/service/purples"
        element={<AuthenticationGuard component={Purples} />}
      />
      <Route
        path="/commissions/reports"
        element={<AuthenticationGuard component={Commissions} />}
      />
      <Route
        path="/commissions/salesmanagers"
        element={<AuthenticationGuard component={SalesManagerRanking} />}
      />
      <Route
        path="/commissions/financemanagers"
        element={<AuthenticationGuard component={FinanceRanking} />}
      />
      <Route
        path="/accounting/expenses"
        element={<AuthenticationGuard component={Expenses} />}
      />
      <Route
        path="/log/cardeals"
        element={<AuthenticationGuard component={CarDeals} />}
      />
      <Route
        path="/log/coversheetlog"
        element={<AuthenticationGuard component={CoverSheetLog} />}
      />
      <Route
        path="/log/reservations"
        element={<AuthenticationGuard component={Reservations} />}
      />
      <Route
        path="/log/gaslog"
        element={<AuthenticationGuard component={GasLog} />}
      />
      <Route
        path="/experiments/expenses"
        element={<AuthenticationGuard component={ExpensesDataGrid} />}
      />
      <Route
        path="/experiments/income"
        element={<AuthenticationGuard component={Income} />}
      />
      <Route
        path="/reports/reportbuilder"
        element={<AuthenticationGuard component={ReportBuilder} />}
      />
      <Route
        path="/reports/pace"
        element={<AuthenticationGuard component={Pace} />}
      />
      <Route
        path="/reports/weekendsummary"
        element={<AuthenticationGuard component={WeekendSummaryTable} />}
      />
      <Route path="/callback" element={<Callback />} />
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
};
