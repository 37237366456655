import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DataGridPro,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';

import { formatCurrency } from 'utils/numberUtils';

import { useInventory } from 'hooks/useInventory';

const queryClient = new QueryClient(); // Declare queryClient outside of component

const TableWithQuery = () => {
  const { isAuthenticated, user } = useAuth0();
  const { inventory, loading, error } = useInventory(user?.cdkid);

  if (error) {
    return <p>Error loading inventory: {error.message}</p>;
  }

  const columns = [
    {
      field: 'StockNo',
      headerName: 'Stock No.',
      width: 100,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
    },
    {
      field: 'EntryDate',
      headerName: 'Age',
      width: 100,
      renderCell: (params) => {
        const entryDateString = params.value;
        const entryDate = new Date(entryDateString);
        const today = new Date();
        const timeDiff = today.getTime() - entryDate.getTime();
        const daysInStock = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return <span>{daysInStock}</span>;
      },
    },
    {
      field: 'StockType',
      headerName: 'Stock Type',
      width: 100,
    },
    {
      field: 'Year',
      headerName: 'Year',
      width: 75,
    },
    {
      field: 'MakeName',
      headerName: 'Make',
      width: 100,
    },
    {
      field: 'ModelName',
      headerName: 'Model',
      width: 150,
    },
    {
      field: 'TrimLevel',
      headerName: 'Trim',
      width: 150,
    },
    {
      field: 'ModelNo',
      headerName: 'Model No.',
      width: 120,
    },
    {
      field: 'Mileage',
      headerName: 'Mileage',
      width: 100,
    },
    {
      field: 'Color',
      headerName: 'Color',
      width: 100,
    },
    {
      field: 'InteriorColor',
      headerName: 'Interior Color',
      width: 150,
    },
    {
      field: 'VIN',
      headerName: 'VIN',
      width: 200,
    },
    {
      field: 'Price1',
      headerName: 'Retail',
      width: 120,
      renderCell: (params) => {
        const priceString = params.value;
        const price = parseFloat(priceString);
        if (isNaN(price)) {
          return priceString; // Return the original value if it cannot be parsed as a number
        }
        return formatCurrency(price);
      },
    },
    {
      field: 'Price2',
      headerName: 'Invoice',
      width: 120,
      renderCell: (params) => {
        const priceString = params.value;
        const price = parseFloat(priceString);
        if (isNaN(price)) {
          return priceString; // Return the original value if it cannot be parsed as a number
        }
        return formatCurrency(price);
      },
    },
    {
      field: 'Balance',
      headerName: 'Balance',
      width: 120,
      renderCell: (params) => {
        const priceString = params.value;
        const price = parseFloat(priceString);
        if (isNaN(price)) {
          return priceString; // Return the original value if it cannot be parsed as a number
        }
        return formatCurrency(price);
      },
    },
    {
      field: 'LicenseExpirationDate',
      headerName: 'LicenseExpirationDate',
      width: 120,
    },
    {
      field: 'InventorySourceCode',
      headerName: 'Source Code',
      width: 150,
    },
    {
      field: 'InventorySourceName',
      headerName: 'Source Name',
      width: 150,
    },
  ];

  return (
    isAuthenticated && (
      <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
          <Typography variant="h6">Inventory</Typography>
        </Box>
        {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <DataGridPro
            sx={{
              borderRadius: 0,
              border: 'none',
            }}
            density="compact"
            rows={inventory}
            columns={columns}
            getRowId={(row) => row._id} // Define the unique 'id' property for each row
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            loading={loading}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      )}
      </Paper>
    )
  );
};

const TableWrapper = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <TableWithQuery />
    </QueryClientProvider>
  );
};

export default TableWrapper;
